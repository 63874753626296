import Vue from 'vue'
import { Toast, Icon } from 'vant'
import './plugins/mint.js'
import App from './App.vue'
import './style/public.scss'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import router from './router/index'
import store from './store/index'
import Axios from './api/index'
import project from './project/index'
import UAParser from 'ua-parser-js'
import BaiduMap from 'vue-baidu-map'
import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
import 'animate.css'
Vue.use(BaiduMap, {
  ak: "8z1lehCbjZlZQIdAx7FF4sjAogFdxLn6"
})

Vue.config.productionTip = false

// 定义懒加载图片或者文件等，自定义指令
Vue.directive('lazy', (el, binding) => {
  debugger
  let oldSrc = el.src //保存旧的src，方便后期渲染时候赋值src真实路径
  el.src = "" //将渲染的src赋为空，则不会渲染图片出来
  let observer = new IntersectionObserver(([{ isIntersecting }]) => { // 调用方法得到该elDOM元素是否处于可视区域
    if (isIntersecting) { //回调是否处于可视区域，true or false
      el.src = oldSrc //如果处于可视区域额，将最开始保存的真实路径赋予DOM元素渲染
      observer.unobserve(el) // 只需要监听一次即可，第二次滑动到可视区域时候不在监听
    }
  })
  observer.observe(el) // 调用方法
})
Vue.use(ElementUI);
Vue.use(Icon)
Vue.use(VueAwesomeSwiper)
Vue.prototype.$http = Axios
Vue.prototype.meetingAlias = project.mid
import Wechat, { wx } from './api/wechat'

const WechatSDK = new Wechat()
Vue.prototype.$shareWeixin = WechatSDK.share
Vue.prototype.$prohibit = WechatSDK.prohibit
Vue.prototype.$wx = wx

Vue.prototype.$scrollOnePx = () => {
  document.querySelectorAll('input[type="text"]').forEach(event => {
    event.onblur = () => {
      const scroll_top = document.documentElement.scrollTop
      if (scroll_top > 0) {
        window.scrollTo(0, scroll_top - 1)
      } else {
        window.scrollTo(0, scroll_top + 1)
      }
    }
  })
}

const os = new UAParser().getOS().name.toLowerCase()

if (os === 'ios') {
  let iosSignatureUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search
  localStorage.setItem('iosSignatureUrl', iosSignatureUrl)
}

Vue.prototype.$getUserInfo = (callback, refresh = false) => {
  const user = store.state.user
  if (user.id && !refresh) {
    if (callback) callback(user)
    return
  }
  Axios.getUserInfo({})
    .then(r => {
      if (r.code == 1) {
        store.commit('userEdit', r.res)
        if (callback) callback(r.res)
      }
    })
    .catch(e => {
      console.error(e)
    })
}

const shareObj = {
  title: '',
  desc: '',
  link: '',
  imgUrl: ''
}

let timer = null
router.afterEach(() => {
  if (timer) {
    clearTimeout(timer)
    timer = null
  }

  timer = setTimeout(() => {
    WechatSDK.share(shareObj)
  }, 1000)
})

router.beforeEach((to, from, next) => {
  // docunent.title = "" || to.meta.name
  if (to.meta.name) {
    document.title = to.meta.name;
  } else {
    document.title = '道生天合-环氧树脂-复合新材料-汽车及高端工业胶'
  }
  if (to.meta.loginAuth) {
    const tokenStr = `${project.mid}token`
    const token = localStorage.getItem(tokenStr)
    if (!token) {
      window.location.href = window.location.protocol + '//' + window.location.host + '/' + project.mid + '/login'
      return
    }
  }

  /* eslint-disable */
  if (to.path) {
    //_hmt.push(['_trackPageview', `/${project.mid}/${to.fullPath}`])
  }
  /* eslint-disable */

  window.scrollTo(0, 0)
  next()

})

Vue.mixin({
  computed: {
    user() {
      return store.state.user
    },
    isiOS() {
      return os === 'ios'
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
