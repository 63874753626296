import Requester from './request.js'
import { serverUrl } from './../utils/index'

const href = '/api'

class Api {
  // 获取用户信息
  // static getUserInfo (data = {}) {
  //   return Requester.post(`${serverUrl + href}/auth/User/getInfo`, data)
  // }
  //获取新闻列表
  static getNewList(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/News/getList`, data)
  }
  //获取新闻信息
  static getNewInfo(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/News/getInfo`, data)
  }
  //获取多媒体列表
  static getMediaList(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Media/getList`, data)
  }
  //获取多媒体信息
  static getMediaInfo(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Media/getInfo`, data)
  }
  //获取产业产品
  static getIndustryList(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Industry/getList`, data)
  }
  //获取产业产品
  static getListTwo(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Industry/getListTwo`, data)
  }
  //获取产业产品信息
  static getIndustryInfo(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Industry/getInfo`, data)
  }
  //获取招聘筛选列表
  static getRecruitList(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Recruit/getList`, data)
  }
  //获取产业产品信息
  static getRecruitListTwo(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Recruit/getListTwo`, data)
  }
  //获取产业产品信息
  static getRecruitInfo(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Recruit/getInfo`, data)
  }
  //获取招聘信息
  static getCommit(data = {}) {
    return Requester.formdata(`${serverUrl + href}/noauth/Recruit/add`, data)
  }
}

export default Api
